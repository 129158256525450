import { action, computed, makeObservable, observable } from 'mobx';
import {
  Supplier as ISupplier,
  ISupplierOption
} from '@link/gds-share/services/types';
import { SUPPLIER_ID, SUPPLIER_LIST } from '@link/gds-share/const';

export default class Supplier {
  nextCursor = '';
  @observable accessor suppliers: Array<ISupplier> = JSON.parse(
    localStorage.getItem(SUPPLIER_LIST) || '[]'
  );
  @observable accessor currentSupplierInfo: ISupplier | null = null;
  @observable accessor currentActivitySupplier: ISupplier | null = null;
  @observable accessor currentSupplierId =
    localStorage.getItem(SUPPLIER_ID) || '';
  @observable accessor loading = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setSupplierInfo = (supplier: ISupplier) => {
    this.currentSupplierInfo = supplier;
  };

  @action
  addSuppliers = (suppliers: Array<ISupplier>) => {
    this.suppliers = this.suppliers.concat(suppliers);
  };

  @action
  clearSuppliers = () => {
    this.suppliers = [];
    this.nextCursor = '';
  };

  @action
  setCursor = (cursor: string) => {
    this.nextCursor = cursor;
  };

  @action
  setSupplierId = (supplierId: string) => {
    try {
      localStorage.setItem(SUPPLIER_ID, supplierId);
    } finally {
      this.currentSupplierId = supplierId;
    }
  };

  @computed
  get supplierName() {
    return this.currentSupplierInfo?.name || '';
  }

  @computed
  get currentSupplier() {
    const supplier = this.suppliers.find(
      supplier => supplier.id === this.currentSupplierId
    );
    return supplier;
  }

  @computed
  get supplierOptions(): ISupplierOption[] {
    return this.suppliers.map(supplier => ({
      label: `${supplier.id} (${supplier.name})`,
      value: supplier.id || ''
    }));
  }
  @computed
  get loaded() {
    return this.suppliers.length > 0 && this.nextCursor === '';
  }
}
