import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Menu } from '@linktivity/link-ui';
import styles from './sidebar.module.css';

const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.inner}>
        <Menu
          className={styles.menu}
          accordion
          defaultExpandedMenu={['contracts']}
        >
          <Menu.MenuItem label={t('views.dashboard.title')} to="/dashboard" />
          <Menu.SubMenu label={t('views.contracts.title')} name="contracts">
            <Menu.MenuItem label={t('views.supplier.title')} to="/supplier" />
            <Menu.MenuItem label={t('views.otasList.title')} to="/otas-list" />
          </Menu.SubMenu>
          <Menu.SubMenu label={t('views.accountManage.title')} name="account">
            <Menu.MenuItem label={t('views.accounts.title')} to="/account" />
          </Menu.SubMenu>
        </Menu>
      </div>
    </aside>
  );
};
const ObserverSidebar = observer(Sidebar);
export default ObserverSidebar;
