import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  useRouteError
} from 'react-router-dom';
import { BaseLayout } from '@link/gds-admin/layouts';
import PrivateRoute from './PrivateRoute';

const Login = lazy(() => import('@link/gds-admin/views/Login'));
const Dashboard = lazy(() => import('@link/gds-admin/views/Dashboard'));
const NotFound = lazy(() => import('@link/gds-share/views/NotFound'));

const SupplierList = lazy(() => import('@link/gds-admin/views/Supplier/List'));
const SupplierDetail = lazy(
  () => import('@link/gds-admin/views/Supplier/Detail')
);

const OtasList = lazy(() => import('@link/gds-admin/views/Otas/List'));
const AccountList = lazy(() => import('@link/gds-admin/views/Accounts/List'));
const AccountDetail = lazy(
  () => import('@link/gds-admin/views/Accounts/Detail')
);

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={lazyView(<Dashboard />)} />
          <Route path="supplier">
            <Route index element={lazyView(<SupplierList />)} />
            <Route path=":supplierId" element={lazyView(<SupplierDetail />)} />
          </Route>
          <Route path="otas-list">
            <Route index element={lazyView(<OtasList />)} />
          </Route>
          <Route path="account">
            <Route index element={lazyView(<AccountList />)} />
            <Route path="detail" element={lazyView(<AccountDetail />)} />
          </Route>
        </Route>
      </Route>
      <Route path="/login" element={lazyView(<Login />)} />
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default Router;
