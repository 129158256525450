import { Suppliermanager } from '@linktivity/link-connect';
import { message } from '@linktivity/link-ui';
import { auth } from '@link/gds-admin/stores';
import { BASE_PATH } from '../constants/env';

type ConfigurationParameters = Suppliermanager.ConfigurationParameters;

const configParams: ConfigurationParameters = {
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json'
        });
        requestHeaders.append(
          'Authorization',
          'Bearer ' + (await auth.getRefreshedToken())
        );
        context.init.headers = requestHeaders;
      },
      post: async context => {
        if (context.response.status === 401) {
          localStorage.clear();
          location.href = '/login';
        } else if (!context.response.ok) {
          // handle error
          const data = await context.response.json();
          message.error(
            data.common.error_description || data.common.error_code,
            10
          );
          return data;
        }
      }
    }
  ]
};

export default configParams;
