import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logoUrl from '@link/gds-share/assets/logo.png';
import { useStore } from '@link/gds-admin/stores';
import User from './User';
import styles from './header.module.css';

const Header: React.FC = () => {
  const { supplier } = useStore();

  useEffect(() => {
    supplier.fetchAllSuppliers();
  }, [supplier]);

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <Link to="/" className={styles.logo}>
          <img src={logoUrl} alt="logo" width="160" />
        </Link>
        <div className={styles.info}>
          <User />
        </div>
      </div>
    </header>
  );
};

export default Header;
