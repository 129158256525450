import { FirebaseAuth } from '@linktivity/link-utils';
import { auth } from '@link/gds-admin/stores';
export type { User, UserCredential } from '@linktivity/link-utils';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN
};

const tenantId = import.meta.env.VITE_APP_FIREBASE_TENANT_ID;

class AdminAuth extends FirebaseAuth {
  constructor() {
    super(firebaseConfig);
    this.setTenantId(tenantId);

    this.auth.onAuthStateChanged(user => {
      if (user) {
        auth.setUser(user);
      } else {
        auth.clearUser();
      }
    });
  }
}

const adminAuth = new AdminAuth();

export const storage = adminAuth.storage;
export default adminAuth;
