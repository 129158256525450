export default {
  common: {
    account: 'アカウント',
    signOut: 'サインアウト',
    no: 'いいえ',
    yes: 'はい',
    add: '追加',
    or: 'または',
    edit: '編集',
    register: '登録',
    save: '保存',
    on: 'オン',
    off: 'オフ',
    cancel: 'キャンセル',
    confirm: '確定',
    email: 'メールアドレス',
    password: 'パスワード',
    role: 'ロール',
    username: 'ユーザー名',
    supplierName: '催行会社',
    editConfirm: '編集の確認',
    directPublish: '公開権限',
    formSaved: '保存しました',
    leaveCurrentPageConfirm: `{{name}}はまだ編集中です
      このページから移動してもよろしいですか？`,
    leaveCurrentPage: 'このページを離れる',
    continueEditing: '編集を続ける'
  },
  views: {
    dashboard: {
      title: 'ダッシュボード'
    },
    login: {
      title: 'Welcome to GDS',
      googleLogin: 'Googleでログイン'
    },
    contracts: {
      title: '契約管理'
    },
    supplier: {
      title: '催行会社',
      supplierId: '催行会社ID',
      detail: '共通情報',
      inputSupplierTitle: '催行会社を入力してください'
    },
    accountDetail: {
      title: '催行会社アカウントを編集'
    },
    otasList: {
      title: '旅行代理店',
      addOta: '旅行代理店を追加',
      otaId: '代理店ID',
      type: 'お支払い方法',
      inputOtaTitle: '旅行代理店を入力してください'
    },
    accountManage: {
      title: 'アカウント管理'
    },
    accounts: {
      title: '催行会社アカウント',
      addAccount: '催行会社アカウントを追加',
      inputAccountEmail: 'メールアドレスを入力してください'
    }
  },
  Role: {
    ROLE_ADMIN: '管理者',
    ROLE_EDITOR: '編集者',
    ROLE_VIEWER: '閲覧者',
    ROLE_OPERATOR: 'オペレーター'
  }
};
