import { action, computed, makeObservable, observable } from 'mobx';
import { sleep } from '@linktivity/link-utils';
import { User } from '@link/gds-admin/vendors/firebase';

const storage = localStorage;
const ACCESS_TOKEN = 'accessToken';

const MAX_REFRESH_COUNT = 10;

const TIMEOUT = 300;

export default class Auth {
  @observable accessor user: User | null = null;
  @observable accessor accessToken = storage.getItem(ACCESS_TOKEN) || '';

  constructor() {
    makeObservable(this);
  }

  @action
  setUser = (user: User) => {
    this.user = user;
  };

  @action
  clearUser = () => {
    this.user = null;
    try {
      storage.removeItem(ACCESS_TOKEN);
    } finally {
      this.accessToken = '';
    }
  };

  @action
  setToken = (token: string) => {
    try {
      storage.setItem(ACCESS_TOKEN, token);
    } finally {
      this.accessToken = token;
    }
  };

  @action
  getToken = async () => {
    if (this.user) {
      const token = await this.user.getIdToken();
      this.setToken(token);
      return token;
    } else {
      return '';
    }
  };

  @action
  getRefreshedToken = async () => {
    let count = 0;
    const requestToken: () => Promise<string> = async () => {
      const token = await this.getToken();
      if (token) {
        return token;
      } else {
        if (count < MAX_REFRESH_COUNT) {
          count++;
          await sleep(TIMEOUT);
          return await requestToken();
        } else {
          return '';
        }
      }
    };
    return await requestToken();
  };

  @computed
  get loggedIn(): boolean {
    return !!this.user || !!this.accessToken;
  }
}
