import React from 'react';
import auth, { Auth } from './modules/auth';
import supplier, { Supplier } from './modules/supplier';

export type RootStore = {
  auth: Auth;
  supplier: Supplier;
};

export const store: RootStore = {
  auth,
  supplier
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { auth, supplier };
