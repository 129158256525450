import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Dropdown, message } from '@linktivity/link-ui';
import { User } from '@linktivity/link-icons';
import { useStore } from '@link/gds-admin/stores';
import adminAuth from '@link/gds-admin/vendors/firebase';
import styles from './header.module.css';

const UserView = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const userButton = useMemo(
    () => (
      <button
        className={styles.button}
        type="button"
        onClick={() => setOpenMenu(visible => !visible)}
      >
        <User className={styles.icon} />
        <span className={styles.text}>{t('common.account')}</span>
      </button>
    ),
    [t]
  );

  const showMessage = useCallback((msg: string) => {
    message.info(msg, 10);
  }, []);

  const logout = useCallback(() => {
    adminAuth.logout();
  }, []);

  return (
    <Dropdown
      target={userButton}
      visible={openMenu}
      sameWidth={false}
      placement="bottom-end"
      onClose={() => setOpenMenu(false)}
    >
      <Dropdown.Menu className={styles.dropdownMenu}>
        <Dropdown.Item>
          <button
            className={styles.dropdownButton}
            onClick={() => showMessage(auth.user?.email || '')}
          >
            <span className={styles.dropdownText}>
              {auth.user?.email || ''}
            </span>
          </button>
        </Dropdown.Item>
        <Dropdown.Item>
          <button className={styles.dropdownButton} onClick={logout}>
            {t('common.signOut')}
          </button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
const ObserverUserView = observer(UserView);
export default ObserverUserView;
